import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false


Vue.use(VueSweetalert2)

Vue.prototype.$convertToMoney = function (x) {
  if (x) {
    x = Number(x)
    return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return '0.00'
}

Vue.prototype.$comparar = function (oldData, newData) {
  let changes = {}

  for (let item in oldData) {
    for (let newItem in newData) {
      if (item == newItem && oldData[item] != newData[newItem]) {
        changes[item] = newData[newItem]
      }
    }
  }
  // Encuentra elementos nuevos en la nueva data
  for (let newItem in newData) {
    if (!oldData.hasOwnProperty(newItem)) {
      changes[newItem] = newData[newItem]
    }
  }
  return changes
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './modules/authModule'
import {axiosbd} from "@/api/axios-bd"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    itemsUsuarios:[],
    tipoIdDocumento:[],
    medioPago:[
      {codigo: '01' , text: 'Efectivo'},
      {codigo: '02' , text: 'Tarjeta'},
      {codigo: '03' , text: 'Cheque'},
      {codigo: '04' , text: 'Transferencia-depósito bancario'},
      {codigo: '05' , text: 'Recaudado por terceros'},
    ],
    genero: [
      {id:'M', descripcion: 'Masculino'},
      {id:'F', descripcion: 'Femenino'},
      {id:'O', descripcion: 'Otro'},
    ],
    frecuencias: [
      {id:'E', descripcion: 'Especifica'},
      {id:'S', descripcion: 'Semanal'},
      {id:'M', descripcion: 'Mensual'},
    ],
    //menu Contratos
    addActivity: false,
    listActivities: false,
    itemsActivities: [],

    addAlerta: false,
    listAlertas: false,
    itemsAlertas: [],
    itemsTipoAlertas: [],

    addMoneda: false,
    addAutoMonda: false,
    listMonedas: false,
    itemsMonedas: [],

    addTipoCedula: false,
    listTipoCedulas: false,
    itemsTipoCedulas: [],

    addTipoAlerta: false,
    listTipoAlertas: false,
    itemsTipoAlertas: [],

    addEstadoContrato: false,
    listEstadoContratos: false,
    itemsEstadoContratos: [],

    addTipoContrato: false,
    addAutoTipoContrato: false,
    addAutoTipoContratoEditar: false,
    selectedTipoContrato: null,
    listTipoContratos: false,
    itemsTipoContratos: [],
    contratoEmailsAlerta: [],

    addTipoTercero: false,
    listTipoTerceros: false,
    itemsTipoTerceros: [],

    addContrato: false,
    listContratos: false,
    itemsContratos: [],

    addAdjunto: false,
    listAdjuntos: false,
    itemsAdjuntos: [],

    addTercero: false,
    listTerceros: false,
    itemsTerceros: [],

    addMiembro: false,
    addMiembroForm: false,
    listMiembros: false,
    itemsMiembros: [],
    itemsAdjuntosJuntas: [],

    //juntas
    addJunta: false,
    addJuntaForm: false,
    listJuntas: false,
    itemsJuntas: [],
    selectedJunta: {},

    addAdjuntoJunta: false,
    listAdjuntoJunta: false,
    itemsAdjuntoJunta: [],

    selectMemberDialog: false,

    itemsMemberType: [],

    itemsSelectedMembers: [],

    //Sesiones
    addSesion: false,
    addSesionForm: false,
    listSesiones: false,
    itemsSesiones: [],
    selectedSesion: {},

    addAdjuntoSesion: false,
    listAdjuntoSesion: false,
    itemsAdjuntoSesion: [],

    itemsAlertasSesiones: [],

    //Puntos de la agenda
    addPuntos: false,
    addPuntosForm: false,
    listPuntos: false,
    itemsPuntos: [],
    selectedPuntos: {},

    taskListManager: false,
    taskListItems: []

  },
  getters: {
    getContratoEmailsAlerta(state){
      const emails = state.contratoEmailsAlerta.map(item => `${item.email} -- ${item.nombre_razon}`)
      console.log(emails)
      return emails
    }
  },
  mutations: {
    MUT_RESET_STATE(state){
      state.addActivity =  false
      state.listActivities =  false
      state.actividadByUser =  []

      state.addAlerta =  false
      state.listAlertas =  false
      state.itemsAlertas =  []

      state.addMoneda = false
      state.listMonedas = false
      state.itemsMonedas = []

      state.addTipoContrato = false
      state.listTipoContratos = false
      state.itemsTipoContratos = []

    },
    MUT_ITENMS_WL(state, data){
      state[data.nombre] = data.data
    },
    MUT_CAT_MONEDAS(state, data){
      state.catMonedas = data
    },
    MUT_ACTIVIDAD_USER(state, data){
      state.actividadByUser = data
    },
    MUT_DEFAULT_ACTI(state, data){
      if (data){
        const defaultAct = data.find(def=> def.activo == 1)
        state.defaultActivity = defaultAct ? `${defaultAct.actividad}-${defaultAct.descripcion}` : ''
      }
    }
  },
  actions: {
    actResetState({commit}){
      commit('MUT_RESET_STATE')
    },
    fechData(_, obj){
      // console.log(obj)
      return new Promise((resolve, reject) =>{
        axiosbd({
          method: obj.method,
          url: obj.url,
          data: obj.body ? obj.body : {},
          params: obj.params ?? undefined,
          headers: {
              Authorization: `Bearer ${obj.token}`,
          },
        })
        .then((res) => {
          // console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
      })
    },
    actItemsBaseWindowsList({commit}, data){
      // console.log(data)
      commit('MUT_ITENMS_WL', data)
    },
    actCatMonedas({commit}, data){
      commit('MUT_CAT_MONEDAS', data)
    },
    actDefaultActivity({commit}, data){
      commit('MUT_DEFAULT_ACTI', data)
    },
  },
  modules: {
    AuthModule
  }
})

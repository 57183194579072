import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue')
  },
  {
    path: '/Home',
    name: 'HomeView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [
      {
        path: '/Reportes',
        name: 'Reportes',
        component: () => import(/* webpackChunkName: "Reportes" */ '../views/Reportes.vue')
      },
      {
        path: '/Configuracion',
        name: 'Configuracion',
        component: () => import(/* webpackChunkName: "Configuracion" */ '../views/Configuracion.vue')
      },
      {
        path: '/Contratos',
        name: 'Contratos',
        component: () => import(/* webpackChunkName: "Contratos" */ '../views/Contratos.vue')
      },
      {
        path: '/Juntas',
        name: 'Juntas',
        component: () => import(/* webpackChunkName: "Juntas" */ '../views/Juntas.vue')
      },
      {
        path: '/Agenda',
        name: 'Agenda',
        component: () => import(/* webpackChunkName: "Agenda" */ '../views/Agenda.vue')
      },

    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const res = await Store.dispatch('AuthModule/verifyToken')
  // console.log(res)
  if (to.name !== 'LoginView') {
    if (res.state) {
      next()
    } else {
      // alert(res.message+" r34")
      // router.history.push('/')
      router.history.push({name: 'LoginView'})
    }
  } else {
    if (res.state) {
      router.history.push({name: 'HomeView'})
    } else {
      console.log('no hay res', res)
      // router.history.push({name: 'LoginView'})
      next()
    }
  }
})

export default router

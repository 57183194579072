import axios from 'axios'


const APIUrl = 'https://juntaxcob.conta506.com/'
// const APIUrl = 'http://127.0.0.1:5000/'

const axiosbd = axios.create({
  baseURL: APIUrl,
  timeout: 50000,
  headers: {
    'Accept-Language': 'es',
  }
})

const getData = axios.create({
  baseURL: APIUrl,
})


//Esta funcion me permite interceptar cualquier 401 cada vez que se utilice 
//axios, a lo cual aplicamos la regla del refresh tokenbundleRenderer.renderToStream
//le damos valor de undefined a la primera parte de la funcion para reemplazar los
//estatus 200 del server

// getData.interceptors.response.use(undefined,function (error) {

//   if(error.response.status === 401){
//     this.$store.dispatch('refreshToken')      
//       .then((newAccessToken) => {
//         axios.request({
//           baseURL: APIUrl,
//           method: error.config.method,
//           headers: { Authorization: `Bearer ${newAccessToken}` }, // the new access token is attached to the authorization header
//           url: error.config.url
//         }).then(response => {
//           // if successfully received the data store it in store.state.APIData so that 'Downloads' component can grab the
//           // data from it and display to the client.
//           console.log('Success getting the Mods')
//           store.state.APIData = response.data
//         }).catch(err => {
//           console.log('Got the new access token but error while trying to fetch data from the API using it')
//           return Promise.reject(err)
//         })        
//       })
//     console.log('========')
//     console.log(error.response)
//     console.log('detecto el error')
//     console.log(error.config)
//     console.log('========')
//     console.log(error.status)
//     console.log('========')
//   }


//   return Promise.reject('boto un error')
// })



export {
  axiosbd,
  getData,
  APIUrl
}
<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  /* color: #546E7A; */
  font-family:  "Open Sans",sans-serif;
}

.v-application a {
  color: #546E7A !important;
  text-decoration: none !important;
}
.text--color{
  color: #546E7A;
}

.theme--light.v-input input{
  color: #546E7A;
}

/* Colores a los botones en las alertas */
.colorCncel{
  background-color: #fa0000 !important;
  border-color: #fa0000 !important;
  margin-left: 0.5rem;
}

.colorConfirm{
  background-color: #0063b4 !important;
  border-color: #0063b4 !important;
  margin-right: 0.5rem;
}


.v-menu__content.theme--light.menuable__content__active{
  border-radius: 24px !important;
}


.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}

.v-dialog.custom-v-dialog.v-dialog--active.v-dialog--persistent{
  border-radius: 24px !important;
}

/* INPUTS DE SOLO NUMEROS CON FORMATO NUMERICO */
.input-totals .v-input__control .v-text-field__details{
  display: none !important;
}
.input-totals .v-input__control .v-input__slot .v-text-field__slot{
  font-size: 12px !important;
}
.input-totals .v-input__control .v-input__slot{
  padding: 0 0.5rem 0 0.5rem !important;
}
.input-totals .v-input__control .v-input__slot input {
  text-align: end !important;
  max-height: 28px !important;
}

/* Redondeo de los bordes de los dialogos */
.v-dialog.v-dialog--active.v-dialog--persistent{
  border-radius: 24px !important;
}


/* chips */
.v-chip.v-size--default{
  height: 25px !important;
}


/* nuevo de agenda */

.auth-layout {
  background: #22d3ee !important;
  background: linear-gradient(90deg, #22d3ee 35%, #0ea5e9 100%) !important;
}

.app-layout {
  background: #f4f5fb !important;
}

.indigo-lighten-6 {
  background: #f4f5fb !important;
}

/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave-to {
  opacity: 0;
}




</style>
